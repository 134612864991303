<template>
  <div style="background-color: #ebf0ff">
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#7CB9E8" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center py-5>
      <v-flex xs10 sm8 text-left>
        <v-layout wrap pb-10>
          <v-flex xs10>
            <span style="font-family: sedanRegular; color: #000000; font-size: 20px">View Asset</span>
          </v-flex>
        </v-layout>
        <v-layout wrap pb-4>
          <v-expansion-panels flat v-model="panel" v-bind:style="{ border: '1px solid #7CB9E8' }">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-flex xs10>
                  <span style="
                                    font-family: sedanRegular;
                                    color: #000000;
                                    font-size: 18px;
                                  ">Asset Details</span>
                </v-flex>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap justify-start pa-4>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Asset ID</span>
                      </v-flex>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.assetID }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Asset Type</span>
                      </v-flex>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.assetType }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs6 sm6 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Asset Name</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.assetName">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.assetName }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs6 sm6 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Transaction Type</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.transactionType">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.transactionType }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <!-- <v-flex xs12 sm12 pt-2 v-if="asset.transactionType=='Transfer'">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span
                          style="
                            font-family: montserratSemiBold;
                            color: #000000;
                            font-size: 14px;
                          "
                          >Transfered To</span
                        >
                      </v-flex>
                      <v-flex xs12>
                        <span
                          style="
                            font-family: montserratMedium;
                            color: #000000;
                            font-size: 14px;
                          "
                          >{{ asset.ownerUid.name }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex> -->
                  <v-flex xs12 sm6 pt-2 v-if="asset.ownerUid">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Owner</span>
                      </v-flex>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.ownerUid.name }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.previousOwnerUid">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Previous Owner</span>
                      </v-flex>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.previousOwnerUid.name }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm12 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Asset Description</span>
                      </v-flex>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        " v-html="asset.description"></span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
        <v-layout wrap pb-4 v-if="asset.assetType == 'Units'">
          <v-expansion-panels flat v-bind:style="{ border: '1px solid #7CB9E8' }">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-flex xs10>
                  <span style="
                                    font-family: sedanRegular;
                                    color: #000000;
                                    font-size: 18px;
                                  ">Unit Details</span>
                </v-flex>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap justify-start pa-4>
                  <v-flex xs12 sm6 v-if="asset.unitHolderEntityName">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Unit Holder Entity Name</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.unitHolderEntityName">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.unitHolderEntityName }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <!-- <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span
                          style="
                            font-family: montserratSemiBold;
                            color: #000000;
                            font-size: 14px;
                          "
                          >Unit Holder Code</span
                        >
                      </v-flex>
                      <v-flex xs12 v-if="asset.unitHolderCode">
                        <span
                          style="
                            font-family: montserratMedium;
                            color: #000000;
                            font-size: 14px;
                          "
                          >{{ asset.unitHolderCode }}</span
                        >
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span
                          style="
                            font-family: montserratMedium;
                            color: #000000;
                            font-size: 14px;
                          "
                          >---</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex> -->
                  <v-flex xs12 sm6 pt-2 v-if="asset.unitsTotalSupply">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Units Total Supply</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.unitsTotalSupply">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.unitsTotalSupply.toLocaleString() }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.unitsBlocked">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Units Blocked</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.unitsBlocked">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.unitsBlocked.toLocaleString() }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.unitsBuffered">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Units Buffered</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.unitsBuffered">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.unitsBuffered.toLocaleString() }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.creationDate">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Creation Date</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.creationDate">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.creationDate.slice(0, 10) }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.expiryDate">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Expiry Date</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.expiryDate">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.expiryDate.slice(0, 10) }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.transferType">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Transfer Type</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.transferType">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.transferType }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.transferType == 'Sale'">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Units Transfered To</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.toId">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.toId.name }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.transferType == 'Purchase'">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Units Transfered From</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.fromId">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.fromId.name }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.noOfUnits">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Number of Units Transfered</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.noOfUnits">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.noOfUnits.toLocaleString() }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.unitsBalance">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Units Balance</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.unitsBalance">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.unitsBalance.toLocaleString() }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.unitsSold">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Total Units Sold</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.unitsSold">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.unitsSold.toLocaleString() }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.heldFrom">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Held From</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.heldFrom">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.heldFrom.slice(0, 10) }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2 v-if="asset.heldTo">
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Held To</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.heldTo">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.heldTo.slice(0, 10) }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
        <v-layout wrap pb-4 v-if="asset.assetType == 'Land' && asset.transactionType == 'Register' ||
          asset.transactionType == 'Update'">
          <v-expansion-panels v-if="
            asset.transactionType == 'Register' ||
            asset.transactionType == 'Update'
          " flat v-bind:style="{ border: '1px solid #7CB9E8' }">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-flex xs10>
                  <span style="
                                    font-family: sedanRegular;
                                    color: #000000;
                                    font-size: 18px;
                                  ">Land Details</span>
                </v-flex>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap justify-center pa-4>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Address</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.address">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.address }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">City</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.city">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.city }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">State</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.state">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.state }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Postcode</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.postCode">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.postCode }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Title number</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.titleNumber">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.titleNumber }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Survey number</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.surveyNumber">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.surveyNumber }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Sub division number</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.subDivisionNumber">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.subDivisionNumber }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Land value</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.landValue">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.landValue }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm12 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Plot size (sq.m)</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.plotSize">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.plotSize }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
        <v-layout wrap pb-4 v-if="asset.assetType == 'Land' && asset.transactionType == 'Register' ||
          asset.transactionType == 'Update'">
          <v-expansion-panels v-if="
            asset.transactionType == 'Register' ||
            asset.transactionType == 'Update'
          " flat v-bind:style="{ border: '1px solid #7CB9E8' }">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-flex xs10>
                  <span style="
                                    font-family: sedanRegular;
                                    color: #000000;
                                    font-size: 18px;
                                  ">Location</span>
                </v-flex>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap justify-center pa-4>
                  <v-flex xs12 pb-4>
                    <span style="
                                      font-family: montserratSemiBold;
                                      color: #000000;
                                      font-size: 16px;
                                    ">GPS Location</span>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Lattitude</span>
                      </v-flex>
                      <v-flex xs12 v-for="(item, i) in asset.gpsLocationCodes" :key="i">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ item.location[1] }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Longitude</span>
                      </v-flex>
                      <v-flex xs12 v-for="(list, j) in asset.gpsLocationCodes" :key="j">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ list.location[0] }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
        <v-layout wrap pb-4 v-if="asset.assetType == 'Land' && asset.transactionType == 'Register' ||
          asset.transactionType == 'Update'">
          <v-expansion-panels v-if="
            asset.transactionType == 'Register' ||
            asset.transactionType == 'Update'
          " flat v-bind:style="{ border: '1px solid #7CB9E8' }">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-flex xs10>
                  <span style="
                                    font-family: sedanRegular;
                                    color: #000000;
                                    font-size: 18px;
                                  ">Project Details
                  </span>
                </v-flex>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap justify-center pa-4>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Project Start Date</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.projectStartDate">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.projectStartDate.slice(0, 10) }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Project End Date</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.projectEndDate">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.projectEndDate.slice(0, 10) }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm12 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Project manager</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.projectManager">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.projectManager }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
        <v-layout wrap pb-4 v-if="asset.assetType == 'Land' && asset.transactionType == 'Register' ||
          asset.transactionType == 'Update'">
          <v-expansion-panels v-if="
            asset.transactionType == 'Register' ||
            asset.transactionType == 'Update'
          " flat v-bind:style="{ border: '1px solid #7CB9E8' }">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-flex xs10>
                  <span style="
                                    font-family: sedanRegular;
                                    color: #000000;
                                    font-size: 18px;
                                  ">Owner Details
                  </span>
                </v-flex>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap justify-center pa-4>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Owner Name</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.ownerName">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.ownerName }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Owner Business Registration number
                        </span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.ownerBusinessRegistrationNumber">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.ownerBusinessRegistrationNumber }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm12 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Owner Individual ID (government issued)</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.ownerIndividualID">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.ownerIndividualID }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>

        <v-layout wrap pb-4 v-if="asset.assetType == 'Land' && asset.transactionType == 'Register' ||
          asset.transactionType == 'Update'">
          <v-expansion-panels v-if="
            asset.transactionType == 'Register' ||
            asset.transactionType == 'Update'
          " flat v-bind:style="{ border: '1px solid #7CB9E8' }">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-flex xs10>
                  <span style="
                                    font-family: sedanRegular;
                                    color: #000000;
                                    font-size: 18px;
                                  ">Lease Ownership</span>
                </v-flex>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap justify-center pa-4>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Owned or Leased (0/L)</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.ownedOrLeased">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.ownedOrLeased }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Lease monthly amount</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.leaseMonthlyAmount">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.leaseMonthlyAmount }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Lease term start</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.leaseTermStart">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.leaseTermStart.slice(0, 10) }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Lease term end</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.leaseTermEnd">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.leaseTermEnd.slice(0, 10) }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm12 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Lease Comments</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.leaseComments">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.leaseComments }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
        <v-layout wrap pb-4 v-if="
          asset.transactionType == 'Register' ||
          asset.transactionType == 'Update'
        ">
          <v-expansion-panels flat v-bind:style="{ border: '1px solid #7CB9E8' }">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-flex xs10>
                  <span style="
                                    font-family: sedanRegular;
                                    color: #000000;
                                    font-size: 18px;
                                  ">Owner Bank Account Details (Private)
                  </span>
                </v-flex>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap justify-center pa-4>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Owner Bank Name</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.ownerBankName">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.ownerBankName }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Owner Bank Branch</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.ownerBankBranch">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.ownerBankBranch }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm12 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Owner Bank Account Number</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.ownerBankAccountNumber">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.ownerBankAccountNumber }}</span>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
        <v-layout wrap pb-4 v-if="asset.assetType == 'Land'">
          <v-expansion-panels v-if="
            asset.transactionType == 'Register' ||
            asset.transactionType == 'Update'
          " flat v-bind:style="{ border: '1px solid #7CB9E8' }">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-flex xs10>
                  <span style="
                                    font-family: sedanRegular;
                                    color: #000000;
                                    font-size: 18px;
                                  ">Species</span>
                </v-flex>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap justify-center pa-4>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Flora</span>
                      </v-flex>
                      <v-flex xs12 v-for="(flora, f) in asset.speciesFlora" :key="f">
                        <span v-if="flora" style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ flora }}</span>
                        <span v-else style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Fauna</span>
                      </v-flex>
                      <v-flex xs12 v-for="(fauna, a) in asset.speciesFauna" :key="a">
                        <span v-if="fauna" style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ fauna }}</span>
                        <span v-else style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm12 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Other</span>
                      </v-flex>
                      <v-flex xs12 v-for="(other, o) in asset.speciesOther" :key="o">
                        <span v-if="other" style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ other }}</span>
                        <span v-else style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm12 pt-2>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span style="
                                          font-family: montserratSemiBold;
                                          color: #000000;
                                          font-size: 14px;
                                        ">Additional Comments</span>
                      </v-flex>
                      <v-flex xs12 v-if="asset.speciesAdditionalComments">
                        <pre style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">{{ asset.speciesAdditionalComments }}</pre>
                      </v-flex>
                      <v-flex xs12 v-else>
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">---</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
        <v-layout wrap pb-4>
          <v-expansion-panels flat v-bind:style="{ border: '1px solid #7CB9E8' }">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-flex xs10>
                  <span style="
                                    font-family: sedanRegular;
                                    color: #000000;
                                    font-size: 18px;
                                  ">Private Files</span>
                </v-flex>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap justify-center pa-4>
                  <v-flex xs12 sm12 pb-5 v-if="asset.privateFilesHashes">
                    <v-layout wrap v-if="asset.privateFilesHashes.length > 0">
                      <v-flex xs12 v-for="(privatName, n) in asset.privateFilesHashes" :key="n" pa-1>
                        <v-card outlined>
                          <v-layout wrap pa-2>
                            <v-flex xs2 sm2 pt-1>
                              <!-- <v-icon color="#68c0e8">mdi-file-multiple</v-icon> -->
                              <span style="
                                                font-family: montserratSemiBold;
                                                color: #000000;
                                                font-size: 14px;
                                              ">File Name :</span>
                            </v-flex>
                            <v-flex xs10 sm10 text-left>
                              <v-card outlined>
                                <v-layout wrap>
                                  <v-flex xs10 md11 text-left pa-2 style="background-color: #d6d1c3">
                                    <v-layout wrap>
                                      <v-flex xs12>
                                        <span :style="{
                                          'font-size':
                                            $vuetify.breakpoint.name == 'xs'
                                              ? '10px'
                                              : $vuetify.breakpoint.name ==
                                                'sm'
                                                ? '13px'
                                                : $vuetify.breakpoint.name ==
                                                  'md'
                                                  ? '10px'
                                                  : $vuetify.breakpoint.name ==
                                                    'lg'
                                                    ? '12px'
                                                    : '12px',
                                        }" style="font-family: poppinssemibold">
                                          {{ privatName.fileName }}</span>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-divider vertical></v-divider>
                                  <v-flex xs2 sm1 md1 text-center align-self-center>
                                    <v-icon @click.stop.prevent="copyImageUrl(n)">
                                      mdi-checkbox-multiple-blank-outline
                                    </v-icon>
                                    <input type="hidden" :id="'testing-code0' + n" :value="privatName.fileName" />
                                  </v-flex>
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap pa-2>
                            <v-flex xs2 sm2 pt-1>
                              <!-- <v-icon color="#68c0e8">mdi-pound-box</v-icon> -->
                              <span style="
                                                font-family: montserratSemiBold;
                                                color: #000000;
                                                font-size: 14px;
                                              ">Hash (SHA256) :</span>
                            </v-flex>
                            <v-flex xs10 sm10 text-left>
                              <v-card outlined>
                                <v-layout wrap>
                                  <v-flex xs10 md11 text-left pa-2 style="background-color: #d6d1c3">
                                    <v-layout wrap>
                                      <v-flex xs12>
                                        <span :style="{
                                          'font-size':
                                            $vuetify.breakpoint.name == 'xs'
                                              ? '10px'
                                              : $vuetify.breakpoint.name ==
                                                'sm'
                                                ? '13px'
                                                : $vuetify.breakpoint.name ==
                                                  'md'
                                                  ? '10px'
                                                  : $vuetify.breakpoint.name ==
                                                    'lg'
                                                    ? '12px'
                                                    : '12px',
                                        }" style="font-family: poppinssemibold">
                                          {{ privatName.fileHash }}</span>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-divider vertical></v-divider>
                                  <v-flex xs2 sm1 md1 text-center align-self-center>
                                    <v-icon @click.stop.prevent="copyImageUrl1(n)">
                                      mdi-checkbox-multiple-blank-outline
                                    </v-icon>
                                    <input type="hidden" :id="'testing-code1' + n" :value="privatName.fileHash" />
                                  </v-flex>
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap v-else>
                      <v-flex>
                        <span style="
                                    font-family: montserratMedium;
                                    color: #000000;
                                    font-size: 15px;
                                  ">No private files ...</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start pa-4 v-if="asset.privateFilesHashes">
                  <v-flex xs10 pb-2 v-if="asset.privateFilesHashes.length > 0">
                    <span style="
                                      font-family: montserratSemiBold;
                                      color: #000000;
                                      font-size: 14px;
                                    ">Users with access:</span>
                  </v-flex>
                  <v-layout wrap>
                    <v-flex xs12 sm12 pa-1 v-for="(access, a) in accessibleUsers" :key="a">
                      <v-chip class="ma-1">
                        <span style="
                                          font-family: montserratMedium;
                                          color: #000000;
                                          font-size: 14px;
                                        ">
                          {{ access.name }}</span>
                      </v-chip>
                    </v-flex>
                  </v-layout>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
        <v-layout wrap pb-16>
          <v-expansion-panels flat v-bind:style="{ border: '1px solid #7CB9E8' }">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-flex xs10>
                  <span style="
                                    font-family: sedanRegular;
                                    color: #000000;
                                    font-size: 18px;
                                  ">Public Files</span>
                </v-flex>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap justify-center pa-4>
                  <v-flex xs12 sm12 md12 pb-5 v-if="asset.publicFilesHashes">
                    <v-layout wrap v-if="asset.publicFilesHashes.length > 0">
                      <v-flex xs12 v-for="(publicName, p) in asset.publicFilesHashes" :key="p" pa-1>
                        <v-card outlined>
                          <v-layout wrap pa-2>
                            <v-flex xs2 sm2 pt-1>
                              <span style="
                                                font-family: montserratSemiBold;
                                                color: #000000;
                                                font-size: 14px;
                                              ">File Name :</span>
                              <!-- <v-icon color="#68c0e8">mdi-file-multiple</v-icon> -->
                            </v-flex>
                            <v-flex xs10 sm10 text-left>
                              <v-card outlined>
                                <v-layout wrap>
                                  <v-flex xs10 md11 text-left pa-2 style="background-color: #d6d1c3">
                                    <v-layout wrap>
                                      <v-flex xs12>
                                        <span :style="{
                                          'font-size':
                                            $vuetify.breakpoint.name == 'xs'
                                              ? '10px'
                                              : $vuetify.breakpoint.name ==
                                                'sm'
                                                ? '13px'
                                                : $vuetify.breakpoint.name ==
                                                  'md'
                                                  ? '10px'
                                                  : $vuetify.breakpoint.name ==
                                                    'lg'
                                                    ? '12px'
                                                    : '12px',
                                        }" style="font-family: poppinssemibold">
                                          {{ publicName.fileName }}</span>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-divider vertical></v-divider>
                                  <v-flex xs2 sm1 md1 text-center align-self-center>
                                    <v-icon @click.stop.prevent="copyImageUrl2(p)">
                                      mdi-checkbox-multiple-blank-outline
                                    </v-icon>
                                    <input type="hidden" :id="'testing-code2' + p" :value="publicName.fileName" />
                                  </v-flex>
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap pa-2>
                            <v-flex xs2 sm2 pt-1>
                              <span style="
                                                font-family: montserratSemiBold;
                                                color: #000000;
                                                font-size: 14px;
                                              ">Hash (SHA256) :</span>
                              <!-- <v-icon color="#68c0e8">mdi-pound-box</v-icon> -->
                            </v-flex>
                            <v-flex xs10 sm10 text-left>
                              <v-card outlined>
                                <v-layout wrap>
                                  <v-flex xs10 md11 text-left pa-2 style="background-color: #d6d1c3">
                                    <v-layout wrap>
                                      <v-flex xs12>
                                        <span :style="{
                                          'font-size':
                                            $vuetify.breakpoint.name == 'xs'
                                              ? '10px'
                                              : $vuetify.breakpoint.name ==
                                                'sm'
                                                ? '13px'
                                                : $vuetify.breakpoint.name ==
                                                  'md'
                                                  ? '10px'
                                                  : $vuetify.breakpoint.name ==
                                                    'lg'
                                                    ? '12px'
                                                    : '12px',
                                        }" style="font-family: poppinssemibold">
                                          {{ publicName.fileHash }}</span>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-divider vertical></v-divider>
                                  <v-flex xs2 sm1 md1 text-center align-self-center>
                                    <v-icon @click.stop.prevent="copyImageUrl3(p)">
                                      mdi-checkbox-multiple-blank-outline
                                    </v-icon>
                                    <input type="hidden" :id="'testing-code3' + p" :value="publicName.fileHash" />
                                  </v-flex>
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap v-else>
                      <v-flex>
                        <span style="
                                    font-family: montserratMedium;
                                    color: #000000;
                                    font-size: 15px;
                                  ">No public files ...</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    ServerError: false,
    showSnackBar: false,
    msg: null,
    timeout: 5000,
    appLoading: false,
    asset: [],
    panel: 0,
    accessibleUsers: [],
  }),
  beforeMount() {
    this.getAsset();
  },
  methods: {
    getAsset() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/asset/details/view/" + this.$route.query.id,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.asset = response.data.data;
            this.accessibleUsers = response.data.accessibleUsers;
          }
          else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    copyImageUrl(n) {
      let testingCodeToCopy = document.querySelector("#testing-code0" + n);
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();
      try {
        var successful = document.execCommand("copy");
        var msg = successful
          ? " File name copied successfully"
          : "unsuccessful";
        this.msg = msg;
        this.showSnackBar = true;
      } catch (err) {
        this.msg = "Oops, unable to copy";
        this.showSnackBar = true;
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    copyImageUrl1(h) {
      let testingCodeToCopy = document.querySelector("#testing-code1" + h);
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();
      try {
        var successful = document.execCommand("copy");
        var msg = successful ? " Hash copied successfully" : "unsuccessful";
        this.msg = msg;
        this.showSnackBar = true;
      } catch (err) {
        this.msg = "Oops, unable to copy";
        this.showSnackBar = true;
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    copyImageUrl2(p) {
      let testingCodeToCopy = document.querySelector("#testing-code2" + p);
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();
      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "File name copied successfully" : "unsuccessful";
        this.msg = msg;
        this.showSnackBar = true;
      } catch (err) {
        this.msg = "Oops, unable to copy";
        this.showSnackBar = true;
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    copyImageUrl3(u) {
      let testingCodeToCopy = document.querySelector("#testing-code3" + u);
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();
      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "Hash copied successfully" : "unsuccessful";
        this.msg = msg;
        this.showSnackBar = true;
      } catch (err) {
        this.msg = "Oops, unable to copy";
        this.showSnackBar = true;
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
};
</script>
